import React from "react"
import PageHeader from "../components/Header"
// import UserDetail from "../components/UserDetail"
import PageSlider from "../components/Slider"
import WelcomePage from "../components/WelcomePage"
// import Selector from "../components/Selector"
// import FreeTrial from "../components/FreeTrial"
import PageFooter from "../components/Footer"
// import TopQuestions from "../components/TopQuestions"
// import LetnyTaborUputavka from "../components/LetnyTaborUputavka"
import Uputavka101Combatives from "../components/Uputavka101Combatives"
import HarmonogramComponent from "../components/HarmonogramComponent"
import Tendaystrial from "../components/Tendaystrial"

    
const HomePage = () => {
    // const dispatch = useDispatch()
    // const { userProfile } = useSelector((state) => state)
    // const debug = JSON.stringify(userProfile, null, 2)

    // const [hasRoles, setHasRoles] = useState(false)
    // const [hasAwards, setHasAwards] = useState(false)
    // const [hasAdultRanks, setHasAdultRanks] = useState(false)
    
    React.useEffect(() => {
        // if (userProfile.roles) { setHasRoles(true) }
        // if (userProfile.awards) { setHasAwards(true) }
        // if (userProfile.adultRanks) { setHasAdultRanks(true) }
        // document.title = 'Brazílske Jiu Jitsu Levice';
    }, [])

    

    return (
        <div>
            <PageHeader />
            <PageSlider />
            {/* <LetnyTaborUputavka /> */}
            {/* <Uputavka101Combatives /> */}
            {/* <TopQuestions /> */}
            <WelcomePage />
            <Tendaystrial />
            <HarmonogramComponent category={["adults-beginers", "adults-pro", "kids"]} />
            {/* <Kontakt /> */}
            <PageFooter />
        </div>
    )
}

export default HomePage

